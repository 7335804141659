import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export var useMedia = function useMedia(query) {
  var _useState = useState(function () {
    return window.matchMedia(query).matches;
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    var mounted = true;
    var mql = window.matchMedia(query);

    var onChange = function onChange() {
      if (!mounted) {
        return;
      }

      setState(!!mql.matches);
    };

    mql.addEventListener('change', onChange);
    setState(mql.matches);
    return function () {
      mounted = false;
      mql.removeEventListener('change', onChange);
    };
  }, [query]);
  return state;
};