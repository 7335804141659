import moment from 'moment';
var LastForcedRefresh = 'LastForcedRefresh';
export var retryPageLoading = function retryPageLoading(error) {
  var lastForcedRefreshDate = JSON.parse(localStorage.getItem(LastForcedRefresh) || 'null');
  var isExpired = !lastForcedRefreshDate || moment(lastForcedRefreshDate).isBefore(moment().subtract(1, 'hour'));
  var isErrorChunkLoadRelated = error.name === 'ChunkLoadError' || error.message === "Unexpected token '<'";

  if (isExpired && isErrorChunkLoadRelated) {
    localStorage.setItem(LastForcedRefresh, JSON.stringify(Date.now()));
    window.location.reload();
  }
};