import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ARENA_COOKIE_PREFIX } from '@/constants';
import { notEmpty } from '../utils';
export function useUserParam(key) {
  var _useState = useState(undefined),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var cookieKey = useMemo(function () {
    return "".concat(ARENA_COOKIE_PREFIX).concat(key);
  }, [key]);
  var setUserParam = useCallback(function (val, expires) {
    if (notEmpty(val)) {
      Cookies.set(cookieKey, val, {
        expires: expires
      });
    } else {
      Cookies.remove(cookieKey);
    }

    setValue(val);
  }, [setValue, cookieKey]);
  useEffect(function () {
    setValue(Cookies.get(cookieKey) || null);
  }, [cookieKey]);
  return [value, setUserParam];
}