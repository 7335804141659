import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ModuleKey } from '@/buildConfig';
import { EXPERIMENTS_MODULES_KEYS } from '@/constants';
import { StringFormatter } from '@/utils/StringFormatter';
export var experimentIdKeySuffix = 'experiment_id';
export var groupIdKeySuffix = 'group_id';
export var groupNameKeySuffix = 'group_name';
var modules = [ModuleKey.QUICK_ORDER, ModuleKey.FORGOTTEN_ITEMS, ModuleKey.CROSS_SELL_UP_SELL].map(function (module) {
  return module.toLowerCase();
});
var MacoTreatmentMetricKeys = ['maco_total'].concat(_toConsumableArray(modules.map(function (module) {
  return "maco_".concat(module);
})));

var NetRevenueTreatmentMetricKeys = _toConsumableArray(modules.map(function (module) {
  return "net_revenue_".concat(module);
}));

export var OrderInfluencedTreatmentMetricKeys = [].concat(_toConsumableArray(modules.map(function (module) {
  return "order_influenced_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "order_influenced_exact_quantity_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "order_influenced_not_corrupted_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "order_influenced_exact_quantity_not_corrupted_".concat(module);
})));
var PercentProductsTreatmentMetricKeys = [].concat(_toConsumableArray(modules.map(function (module) {
  return "percent_products_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "percent_products_exact_quantity_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "percent_products_not_corrupted_".concat(module);
})), _toConsumableArray(modules.map(function (module) {
  return "percent_products_exact_quantity_not_corrupted_".concat(module);
})));

var PercentViewedToAddedTreatmentMetricKeys = _toConsumableArray(modules.map(function (module) {
  return "percent_viewed_to_added_".concat(module);
}));

export var PrecisionAllProductsTreatmentMetricKeys = ['precision_at_1_all_products', 'precision_at_3_all_products', 'precision_at_5_all_products'];
export var PrecisionTopTenProductsTreatmentMetricKeys = ['precision_at_1_top_10_products', 'precision_at_3_top_10_products', 'precision_at_5_top_10_products'];
export var PrecisionTreatmentMetricKeys = [].concat(PrecisionAllProductsTreatmentMetricKeys, PrecisionTopTenProductsTreatmentMetricKeys);
export var PromosTreatmentMetricKeys = ['maco_per_poc', 'investment_per_poc', 'purchase_percentage'];
export var VolumeIndexAllProductsMetricKeys = ['volume_index_all_products', 'volume_index_on_tp_all_products'];
export var VolumeIndexTopTenProductsMetricKeys = ['volume_index_top_10_products', 'volume_index_on_tp_top_10_products'];
export var VolumeIndexMetricKeys = [].concat(VolumeIndexAllProductsMetricKeys, VolumeIndexTopTenProductsMetricKeys);
export var WeightedAllProductsMetricKeys = ['weighted_precision_score_all_products', 'weighted_recall_score_all_products'];
export var WeightedTopTenProductsMetricKeys = ['weighted_precision_score_top_10_products', 'weighted_recall_score_top_10_products'];
export var WeightedMetricKeys = [].concat(WeightedAllProductsMetricKeys, WeightedTopTenProductsMetricKeys);
export var MapeMetricKeys = ['mape_all_products', 'mape_top_10_products'];
export var PrecisionScoreMetricKeys = ['precision_score_all_products', 'precision_score_top_10_products'];
export var RecallMetricKeys = ['recall_score_all_products', 'recall_score_top_10_products'];
export var QOOnlyMetrics = [].concat(_toConsumableArray(PrecisionTreatmentMetricKeys), _toConsumableArray(WeightedMetricKeys), _toConsumableArray(VolumeIndexMetricKeys), MapeMetricKeys, PrecisionScoreMetricKeys, RecallMetricKeys);
export var TreatmentMetricKeys = [].concat(_toConsumableArray(MacoTreatmentMetricKeys), _toConsumableArray(NetRevenueTreatmentMetricKeys), _toConsumableArray(OrderInfluencedTreatmentMetricKeys), _toConsumableArray(PercentProductsTreatmentMetricKeys), _toConsumableArray(PercentViewedToAddedTreatmentMetricKeys), PromosTreatmentMetricKeys, _toConsumableArray(QOOnlyMetrics));
export var TreatmentMetricNames = {};
export var crossSellUpSellLowerCase = ModuleKey.CROSS_SELL_UP_SELL.toLowerCase();
export var MetricSuffixByProduct;

(function (MetricSuffixByProduct) {
  MetricSuffixByProduct["all_products"] = "all_products";
  MetricSuffixByProduct["top_10_products"] = "top_10_products";
  MetricSuffixByProduct["general_metrics"] = "general_metrics";
})(MetricSuffixByProduct || (MetricSuffixByProduct = {}));

export var METRIC_SUFFIXES_BY_PRODUCT = [MetricSuffixByProduct.all_products, MetricSuffixByProduct.top_10_products, MetricSuffixByProduct.general_metrics];
export var METRIC_VARIANT_BY_PRODUCT_MAP = {
  all_products: 'All products',
  top_10_products: 'Top 10 products',
  general_metrics: 'General metrics'
};
export var METRIC_VARIANT_BY_PRODUCT_ORDER_MAP = {
  general_metrics: 0,
  all_products: 1,
  top_10_products: 2
};
TreatmentMetricKeys.forEach(function (item) {
  var value = item;
  value = value.replace(crossSellUpSellLowerCase, 'upsell');
  var isAllOrTopTen = METRIC_SUFFIXES_BY_PRODUCT.some(function (prodItem) {
    return item.endsWith(prodItem);
  });

  if (isAllOrTopTen) {
    value = item.replace(/_all_products|_top_10_products/g, '');
  }

  if (item.startsWith('volume_index_on_tp')) {
    value = 'Volume Index – True positives';
  }

  var formattedValue = StringFormatter.toSentenceCaseWithoutUnderscoreString(value);
  TreatmentMetricNames[item] = formattedValue.replace(/poc/gi, 'POC').replace(/maco/gi, 'MACO');
});
export var METRIC_MODULE_KEYS = [].concat(_toConsumableArray(EXPERIMENTS_MODULES_KEYS.filter(function (item) {
  return item !== 'ALL' && item !== 'UPSELL';
}).map(function (item) {
  return item.toLowerCase();
})), [crossSellUpSellLowerCase]);