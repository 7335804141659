export var UserParamKey;

(function (UserParamKey) {
  UserParamKey["USER_DISMISS_INSPECT_INTRO"] = "User_dismiss_inspect_intro";
  UserParamKey["DASHBOARD_DATE_RANGE_VALUE"] = "Dashboard_Date_Range_Value";
  UserParamKey["SOLOMON_DATE_RANGE_VALUE"] = "Solomon_Date_Range_Value";
  UserParamKey["SOLOMON_METRIC_VALUE"] = "Solomon_Metric_Value";
  UserParamKey["USERNAME"] = "Username";
  UserParamKey["USERID"] = "UserID";
  UserParamKey["POC_URL_PARAM"] = "POC_Url_Param";
})(UserParamKey || (UserParamKey = {}));