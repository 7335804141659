import { ANTARCTICA_COUNTRY_CODES } from './countryCodes';
import { ModuleKey } from './moduleKey';
import { AuthProviderKey, BEEsRecommendationsBackend, ClientBrandKey, NavBarItemKey, TableServiceMajorPartition } from './types';
export var buildConfigDefault = {
  isProduction: false,
  arenaGeneral: {
    supportEmail: 'support@arena-ai.com',
    apiUrl: '',
    clientBrand: ClientBrandKey.None
  },
  htmlVars: {
    segment: '',
    useChatlio: false
  },
  navigation: {
    navBarItems: undefined,
    navBarBottomItems: [],
    hiddenRoutes: [],
    defaultPath: NavBarItemKey.Developer,
    arenaStaffRoutes: [NavBarItemKey.Developer, NavBarItemKey.Helm]
  },
  auth: {
    providers: [AuthProviderKey.ArenaStaff]
  },
  tableService: {
    partitionIsCountryCode: false,
    allPartitionsId: 'WORLD',
    partitionNoun: 'Country'
  },
  inspect: {
    defaultPartition: '',
    defaultMetric: 'volume_index',
    additionalMetrics: [],
    allMetricsWhitelist: undefined,
    drilldownAccessWhitelist: undefined
  },
  inspectV2: {
    tableConfig: {},
    datasetOptions: [],
    datasetNoun: 'Metric',
    defaultDateRange: 'LAST_4_MONTHS',
    useWholesalers: false,
    showCountry: false
  },
  dashboard: {
    beesApiUrl: ''
  },
  status: {
    tabs: [],
    partitions: ANTARCTICA_COUNTRY_CODES,
    defaultPartition: 'AR'
  },
  recommendationViewer: {
    modules: [ModuleKey.QUICK_ORDER],
    hideModuleSettings: false,
    hideArenaRecsForModules: [],
    hackLola: [],
    showWholesaler: false,
    showAccountForkId: false,
    hideSKU: false,
    showBeesPostBusinessRecs: true,
    tableservicePartition: TableServiceMajorPartition.Country,
    backend: BEEsRecommendationsBackend.Antarctica,
    beesRecommendationsEndpoint: '',
    examples: [],
    defaultPocCode: '',
    defaultWholesaler: '',
    filterNonAbiProducts: false,
    pocLookup: false,
    billingProductIdentifier: 'high_pid',
    prod: {
      beesApiUrl: '',
      beesApiUrlByRegion: {}
    },
    uat: {
      beesApiUrl: '',
      beesApiUrlByRegion: {}
    }
  },
  experiments: {
    modules: [],
    countries: ANTARCTICA_COUNTRY_CODES,
    hideCountry: false
  },
  promotions: {
    arenaAccessWhiteList: undefined
  },
  moduleStatus: {
    modules: []
  },
  helm: {
    performanceTileDefaults: {
      aggMethod: 'sum',
      context: '',
      reward: ''
    },
    goalTileDefaults: {
      maximizeMACO: ''
    },
    decisionsTileDefaults: {
      actionSpace: ''
    },
    decisionsDistributionTileDefaults: {
      actionSpace: '',
      context: ''
    }
  },
  developer: {
    showPipelineVisualizer: false
  },
  moduleSettings: {
    countries: [],
    strategicSkuCountries: [],
    modules: []
  },
  shouldPersistCountry: false,
  camouflage: false,
  pocBrowser: {
    defaultSelect: [],
    columnNameMap: {}
  }
};