import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
  currentPage: 1,
  nextPageExists: true,
  promoSpecs: [],
  downloadLink: null,
  error: null
};
var viewPromosSlice = createSlice({
  name: 'viewPromos',
  initialState: initialState,
  reducers: {
    resetState: function resetState(state) {
      state.currentPage = 1;
      state.nextPageExists = true;
      state.promoSpecs = [];
      state.downloadLink = null;
      state.error = null;
    },
    nextPage: function nextPage(state) {
      state.currentPage++;
    },
    prevPage: function prevPage(state) {
      state.currentPage--;
    },
    addPromos: function addPromos(state, action) {
      if (!action.payload || action.payload.length < 10) {
        state.nextPageExists = false;
      } else {
        state.nextPageExists = true;
      }

      if (action.payload) {
        state.promoSpecs = _toConsumableArray(action.payload);
      }
    },
    setDownloadLink: function setDownloadLink(state, action) {
      state.downloadLink = action.payload;
    },
    setError: function setError(state, action) {
      state.error = action.payload;
    }
  }
});
var _viewPromosSlice$acti = viewPromosSlice.actions,
    resetState = _viewPromosSlice$acti.resetState,
    nextPage = _viewPromosSlice$acti.nextPage,
    prevPage = _viewPromosSlice$acti.prevPage,
    addPromos = _viewPromosSlice$acti.addPromos,
    setDownloadLink = _viewPromosSlice$acti.setDownloadLink,
    setError = _viewPromosSlice$acti.setError;
export { resetState, nextPage, prevPage, addPromos, setDownloadLink, setError };
export var viewPromosReducer = viewPromosSlice.reducer;