export var ModuleKeys;

(function (ModuleKeys) {
  ModuleKeys["QUICK_ORDER"] = "Suggested order";
  ModuleKeys["UPSELL"] = "Upsell popup";
  ModuleKeys["FORGOTTEN_ITEMS"] = "Forgotten items";
  ModuleKeys["PROMOS"] = "Promotions";
  ModuleKeys["SKU_LIMITS"] = "Sku Limits";
})(ModuleKeys || (ModuleKeys = {}));

export var SettingKey;

(function (SettingKey) {
  SettingKey["title"] = "title";
  SettingKey["description"] = "description";
  SettingKey["imageApp"] = "imageApp";
  SettingKey["imageWeb"] = "imageWeb";
  SettingKey["cartAddTriggerThreshold"] = "cartAddTriggerThreshold";
  SettingKey["cartViewTriggerThreshold"] = "cartViewTriggerThreshold";
  SettingKey["defaultRecs"] = "defaultRecs";
  SettingKey["strategicSkus"] = "strategicSkus";
  SettingKey["baselineSkuLimitQuantity"] = "baselineSkuLimitQuantity";
  SettingKey["extraSkuLimitQuantityMultiplier"] = "extraSkuLimitQuantityMultiplier";
})(SettingKey || (SettingKey = {}));

export var NetworkStatus;

(function (NetworkStatus) {
  NetworkStatus["NO_STATUS"] = "";
  NetworkStatus["SAVING_CHANGES"] = "Saving changes";
  NetworkStatus["CHANGES_NOT_SAVED"] = "Changes not yet saved...";
  NetworkStatus["CHANGES_ARE_SAVED"] = "Settings saved";
  NetworkStatus["LOADING"] = "Loading";
  NetworkStatus["LOADING_FAILED"] = "Loading failed";
})(NetworkStatus || (NetworkStatus = {}));