import { BUILD_CONFIG } from '@/buildConfig';
export function filteringNavBarButtonProps(navItems, userGroups) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    isProduction: false
  },
      isProduction = _ref.isProduction;

  var userIsArenaStaff = userGroups === null || userGroups === void 0 ? void 0 : userGroups.includes('arena-staff');
  var productionCheck = isProduction && userIsArenaStaff;
  var developmentCheck = !isProduction;
  if (productionCheck || developmentCheck) return navItems;
  return navItems.filter(function (item) {
    var _BUILD_CONFIG$navigat;

    return !((_BUILD_CONFIG$navigat = BUILD_CONFIG.navigation.arenaStaffRoutes) !== null && _BUILD_CONFIG$navigat !== void 0 && _BUILD_CONFIG$navigat.includes(item.navKey));
  });
}