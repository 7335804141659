import _ from 'lodash';
import { useQuery } from './useQuery';
export var useTableServiceMetaData = function useTableServiceMetaData(alias, partition) {
  var url = alias && partition ? "/table-service/api/tables/registration/describe/".concat(partition.toLocaleLowerCase(), "/").concat(alias) : undefined;
  return useQuery(url, undefined, undefined, true);
};
var colBlackList = ['_chunk_hash', '#n/d'];
export var getSchemaCols = function getSchemaCols(metadata) {
  var _metadata$table_schem;

  return _(metadata === null || metadata === void 0 ? void 0 : (_metadata$table_schem = metadata.table_schema) === null || _metadata$table_schem === void 0 ? void 0 : _metadata$table_schem.columns).keys().sort().filter(function (item) {
    return !colBlackList.includes(item);
  }).value();
};