import _ from 'lodash';
export function executeQuery(tableClient, cancelSource, partitionArray, serviceName, request, ignore404) {
  var _request$offset;

  var urlPrefix = '/tables/read/';
  var paginationParams = {
    offset: "".concat((_request$offset = request.offset) !== null && _request$offset !== void 0 ? _request$offset : 0)
  };
  if (!_.isNil(request.limit)) paginationParams.limit = "".concat(request.limit);

  var parsedRequest = _.omit(request, ['offset', 'limit']);

  var urlSearchParams = new URLSearchParams(paginationParams).toString();
  var paginationQueryString = urlSearchParams ? "?".concat(urlSearchParams) : '';
  var queryArray = partitionArray.map(function (c) {
    return serviceName.map(function (s) {
      return tableClient.post("".concat(urlPrefix).concat(c.toLocaleLowerCase(), "/").concat(s).concat(paginationQueryString), parsedRequest, ignore404, {
        cancelToken: cancelSource.token
      })["catch"](function (err) {
        return err;
      });
    });
  });
  return queryArray.flat();
}