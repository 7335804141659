import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { notEmpty } from '@/utils';
import { DEFAULT_ALLOCATION_PCT, DEFAULT_SKU_CONFIG_BY_COUNTRY } from './constants';
export function processSKUName(sku) {
  var highPid = sku.highPid,
      containerType = sku.containerType,
      skuCode = sku.sku;
  return "".concat(highPid, " ").concat(containerType, " (").concat(skuCode, ")");
}
export function skuToConfiguredSku(sku, selectedCountry) {
  var defaultConfig = {};

  if (notEmpty(selectedCountry)) {
    var configForCountry = DEFAULT_SKU_CONFIG_BY_COUNTRY[selectedCountry];

    if (notEmpty(configForCountry)) {
      defaultConfig = configForCountry;
    }
  }

  return _objectSpread(_objectSpread({}, sku), {}, {
    minQuantity: undefined,
    maxQuantity: undefined,
    minDiscountPct: undefined,
    maxDiscountPct: undefined,
    allocationPct: DEFAULT_ALLOCATION_PCT
  }, defaultConfig);
}

function extractValuesFromOptions(options) {
  return options.map(function (o) {
    return o.value;
  });
}

function generateFiltersFromOptions(optionsList, columnNames) {
  var filters = [];
  optionsList.forEach(function (options, index) {
    var values = extractValuesFromOptions(options);

    if (values.length > 0) {
      var joinedValues = values.join(',');
      filters.push({
        column: columnNames[index],
        comparator: 'isin',
        value: joinedValues
      });
    }
  });
  return filters;
}

export function buildFilterSpecInputs(config) {
  var geographyFilters = config.geographyFilters,
      geography2Filters = config.geography2Filters,
      geography3Filters = config.geography3Filters,
      businessTypeFilters = config.businessTypeFilters,
      businessType2Filters = config.businessType2Filters,
      businessType3Filters = config.businessType3Filters;
  var optionValues = [geographyFilters, geography2Filters, geography3Filters, businessTypeFilters, businessType2Filters, businessType3Filters];
  var columnNames = ['promo_geography', 'promo_geography2', 'promo_geography3', 'promo_business_type', 'promo_business_type2', 'promo_business_type3'];
  var filters = generateFiltersFromOptions(optionValues, columnNames);
  return filters;
}