import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { downloadURL } from '@/utils';
export var useDownloadFn = function useDownloadFn(fn) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var snackBar = useSnackbar();
  var downloadFn = useCallback(function () {
    if (!fn) return;
    setLoading(true);
    fn().then(function (url) {
      if (url) {
        downloadURL(url);
      }
    })["catch"](function (error) {
      snackBar.enqueueSnackbar(error.message, {
        variant: 'error'
      });
    })["finally"](function () {
      setLoading(false);
    });
  }, [setLoading, fn, snackBar]);
  return [downloadFn, loading];
};