import { StringFormatter } from './StringFormatter';

var defaultFormatter = function defaultFormatter(val) {
  return StringFormatter.toSentenceCaseWithoutUnderscoreString("".concat(val));
};

export var generateDropdownOptions = function generateDropdownOptions(values, labelFormatter) {
  if (!values) return [];
  var formatter = labelFormatter || defaultFormatter;
  return values.map(function (opt) {
    return {
      value: opt,
      label: "".concat(formatter(opt))
    };
  });
};
var limitOptions = [250, 500, 1000, 2500, 5000, 10000];
export var generateLimitOptions = function generateLimitOptions(numbers) {
  return generateDropdownOptions(numbers, function (num) {
    return "".concat(num, " data points maximum");
  });
};
export var limitDropdownOptions = generateLimitOptions(limitOptions);