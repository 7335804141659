import { ANTARCTICA_COUNTRY_CODES, BUILD_CONFIG, IS_DEV, ModuleKey } from '@/buildConfig';
import { ENV_PROD_KEY, ENV_UAT_KEY } from './envKey';
export var SOLOMON_DEFAULT_DATE_RANGE = 'LAST_30_DAYS';
export var DASHBOARD_DEFAULT_DATE_VALUE = 'LAST_365_DAYS';
export var POC_DEFAULT_DATE_VALUE = 'LAST_TWO_MONTHS';
export var EXPERIMENT_DEFAULT_COUNTRY_CODE = 'AR';
export var EXPERIMENT_DEFAULT_MODULE_KEY = ModuleKey.QUICK_ORDER;
export var EXPERIMENT_DEFAULT_ENV = IS_DEV ? ENV_UAT_KEY : ENV_PROD_KEY;
export var OUTPUT_DEBUGGER_DEFAULT_ENV = ENV_PROD_KEY;
export var OUTPUT_DEBUGGER_DEFAULT_COUNTRY_CODE = 'DO';
export var OUTPUT_DEBUGGER_DEFAULT_MODULE = ModuleKey.QUICK_ORDER;
export var VALKYRIE_GLOBAL_DEFAULT_COUNTRY = 'BR';
export var VALKYRIE_DEFAULT_MODULE = 'ALL';
export var VALKYRIE_MAP_LEGEND_TITLES = ['ALGO - EXPLORE', 'ALGO - OPTIMIZE', 'CLASSIC'];
export var VALKYRIE_GLOBAL_MAP_LEGEND_TITLES = [BUILD_CONFIG.camouflage ? 'LAUNCH' : 'INTRODUCE', 'SUSTAIN', 'GENERATE DEMAND', 'OPERATIONAL', 'ALLOCATED POCS'];
export var VALKYRIE_GLOBAL_MAP_ALLOCATED_LEGEND_TITLES = ['ALLOCATED', 'MISSING', 'INCORRECT'];
export var VALKYRIE_DEMO_MODULE = 'BR';
export var ANTARCTICA_DASHBOARD_DEFAULT_COUNTRY = ANTARCTICA_COUNTRY_CODES[0];
export var RECOMMENDATIONS_DEFAULT_COUNTRY = 'DO';