import { IS_DEMO } from '@/buildConfig';
export var EXPERIMENT_GROUP_SOURCE_MAP = {
  statusQuo: 'Status quo',
  rl: 'RL Agent',
  python: 'Python',
  gsheet: 'G Sheet',
  csv: 'CSV file',
  modelId: 'Model ID',
  model: 'JSON'
};
export var EXPERIMENT_GROUP_SOURCE_LIST = IS_DEMO ? ['statusQuo', 'rl', 'python', 'gsheet', 'csv'] : ['modelId', 'model'];
export var EXPERIMENT_GROUP_SOURCE_TEXTAREA = ['model', 'python'];