import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { TABLE_BASE_URL } from '@/clients/endpoints';
import { useQuery } from './useQuery';
export var useTableServiceEvents = function useTableServiceEvents() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$limit = props.limit,
      limit = _props$limit === void 0 ? 1000 : _props$limit,
      startTsUtcBegin = props.startTsUtcBegin,
      startTsUtcEnd = props.startTsUtcEnd;
  var urlSearchParams = new URLSearchParams({
    limit: limit.toString()
  });

  if (startTsUtcBegin) {
    urlSearchParams.append('start_timestamp_utc_begin', startTsUtcBegin);
  }

  if (startTsUtcEnd) {
    urlSearchParams.append('start_timestamp_utc_end', startTsUtcEnd);
  }

  var queryStr = urlSearchParams.toString();
  var alias = "".concat(TABLE_BASE_URL, "tables/read/events?").concat(queryStr);

  var _useQuery = useQuery(alias),
      _useQuery2 = _slicedToArray(_useQuery, 3),
      events = _useQuery2[0],
      eventsLoading = _useQuery2[1],
      eventsError = _useQuery2[2];

  return {
    events: events,
    eventsLoading: eventsLoading,
    eventsError: eventsError
  };
};