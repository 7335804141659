export var RestClientType;

(function (RestClientType) {
  RestClientType[RestClientType["Abacus"] = 0] = "Abacus";
  RestClientType[RestClientType["ExternalAbacus"] = 1] = "ExternalAbacus";
  RestClientType[RestClientType["SwitchingAbacus"] = 2] = "SwitchingAbacus";
  RestClientType[RestClientType["Auth"] = 3] = "Auth";
  RestClientType[RestClientType["Experiments"] = 4] = "Experiments";
  RestClientType[RestClientType["Table"] = 5] = "Table";
  RestClientType[RestClientType["HelpUs"] = 6] = "HelpUs";
  RestClientType[RestClientType["General"] = 7] = "General";
})(RestClientType || (RestClientType = {}));