import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export var useStateWithDeps = function useStateWithDeps(value) {
  var _useState = useState(value),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    setState(value);
  }, [value]);
  return [state, setState];
};