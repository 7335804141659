import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export var useRedirectUrl = function useRedirectUrl(basePath) {
  var _useLocation = useLocation(),
      pathname = _useLocation.pathname,
      search = _useLocation.search;

  var redirectUrl = useMemo(function () {
    if (pathname === '/') return basePath;
    return "".concat(basePath, "?redirect=").concat(pathname).concat(search);
  }, [pathname, search]);
  return {
    redirectUrl: redirectUrl
  };
};