import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import cx from 'classnames';
import { useCallback, useState } from 'react';
var defaultAppClassName = 'h-full min-h-screen w-screen relative px-2 sm:px-12 mt-16 overflow-x-hidden';
var defaultAppStyle = {};
export var useAppStyleContextDefault = function useAppStyleContextDefault() {
  var _useState = useState(defaultAppClassName),
      _useState2 = _slicedToArray(_useState, 2),
      appContainerClassName = _useState2[0],
      setAppContainerClassName = _useState2[1];

  var _useState3 = useState(defaultAppStyle),
      _useState4 = _slicedToArray(_useState3, 2),
      appContainerStyle = _useState4[0],
      setAppContainerStyle = _useState4[1];

  var addAppContainerClass = useCallback(function (className) {
    if (appContainerClassName.includes(className)) return;
    setAppContainerClassName(cx(appContainerClassName, className));
  }, [appContainerClassName, setAppContainerClassName]);
  var removeAppContainerClass = useCallback(function (className) {
    setAppContainerClassName(function (current) {
      return current.replace(className, '');
    });
  }, [setAppContainerClassName]);
  var resetAppContainerClass = useCallback(function () {
    setAppContainerClassName(defaultAppClassName);
  }, [setAppContainerClassName]);
  var updateAppContainerStyle = useCallback(function (style) {
    setAppContainerStyle(function (current) {
      return _objectSpread(_objectSpread({}, current), style);
    });
  }, [setAppContainerStyle]);
  var resetAppContainerStyle = useCallback(function () {
    setAppContainerStyle(defaultAppStyle);
  }, [setAppContainerStyle]);
  return {
    appContainerClassName: appContainerClassName,
    addAppContainerClass: addAppContainerClass,
    removeAppContainerClass: removeAppContainerClass,
    resetAppContainerClass: resetAppContainerClass,
    appContainerStyle: appContainerStyle,
    updateAppContainerStyle: updateAppContainerStyle,
    resetAppContainerStyle: resetAppContainerStyle
  };
};