import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import moment from 'moment';
export var DateRangeOptions = [{
  value: 'LAST_7_DAYS',
  label: 'Last 7 days'
}, {
  value: 'LAST_30_DAYS',
  label: 'Last 30 days'
}, {
  value: 'PREVIOUS_MONTH',
  label: 'Previous month'
}, {
  value: 'THIS_QUARTER',
  label: 'This quarter'
}, {
  value: 'PREVIOUS_QUARTER',
  label: 'Previous quarter'
}, {
  value: 'LAST_4_MONTHS',
  label: 'Last 4 months'
}, {
  value: 'YEAR_TO_DATE',
  label: 'Year to date'
}, {
  value: 'LAST_365_DAYS',
  label: 'Last 365 days'
}, {
  value: 'PREVIOUS_YEAR',
  label: 'Previous year'
}, {
  value: 'LAST_2_YEARS',
  label: 'Last 2 years'
}, {
  value: 'LAST_3_YEARS',
  label: 'Last 3 years'
}, {
  value: 'LAST_4_YEARS',
  label: 'Last 4 years'
}];
export var frequencyOptions = [{
  value: 'year',
  label: 'Yearly'
}, {
  value: 'quarter',
  label: 'Quarterly'
}, {
  value: 'month',
  label: 'Monthly'
}, {
  value: 'week',
  label: 'Weekly'
}, {
  value: 'day',
  label: 'Daily'
}];
export var DATE_RANGES = DateRangeOptions.map(function (option) {
  return option.value;
});

var calculateRange = function calculateRange(option) {
  switch (option) {
    case 'LAST_28_DAYS':
      return [moment().subtract(28, 'days'), moment()];

    case 'LAST_30_DAYS':
      return [moment().subtract(30, 'days'), moment()];

    case 'LAST_TWO_MONTHS':
      return [moment().subtract(2, 'months'), moment()];

    case 'PREVIOUS_MONTH':
      return [moment().subtract(30, 'days').startOf('month'), moment().subtract(30, 'days').endOf('month')];

    case 'LAST_7_DAYS':
      return [moment().subtract(7, 'days'), moment()];

    case 'THIS_QUARTER':
      return [moment().startOf('quarter'), moment()];

    case 'PREVIOUS_QUARTER':
      return [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')];

    case 'LAST_4_MONTHS':
      return [moment().subtract(4, 'months'), moment()];

    case 'LAST_6_MONTHS':
      return [moment().subtract(6, 'months'), moment()];

    case 'YEAR_TO_DATE':
      return [moment().startOf('year'), moment()];

    case 'LAST_365_DAYS':
      return [moment().subtract(365, 'days'), moment()];

    case 'PREVIOUS_YEAR':
      return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];

    case 'LAST_2_YEARS':
      return [moment().subtract(2, 'years'), moment()];

    case 'LAST_3_YEARS':
      return [moment().subtract(3, 'years'), moment()];

    case 'LAST_4_YEARS':
      return [moment().subtract(4, 'years'), moment()];

    default:
      throw new Error('Option not valid');
  }
};

export var getDateRange = function getDateRange(option) {
  var _calculateRange = calculateRange(option),
      _calculateRange2 = _slicedToArray(_calculateRange, 2),
      from = _calculateRange2[0],
      to = _calculateRange2[1];

  return {
    from: from.startOf('day'),
    to: to.endOf('day'),
    value: option
  };
};