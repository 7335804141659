import { useContext } from 'react';
import { AppContext, AuthContext } from '@/contexts';
export function usePrebuiltDownload(alias, partition) {
  var _useContext = useContext(AppContext),
      tableClient = _useContext.tableClient;

  var _useContext2 = useContext(AuthContext),
      authToken = _useContext2.authToken;

  if (!tableClient) throw new Error('Table client must be ready before calling usePrebuiltDownload');
  if (!authToken) throw new Error('Auth token must be ready before calling usePrebuiltDownload');
  return "".concat(tableClient.getBaseURL(), "tables/read/download-s3-path/").concat(partition.toLowerCase(), "/").concat(alias, "?token=").concat(authToken);
}