import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { AppContext } from '../contexts';

var useFetcher = function useFetcher(params, ignore404) {
  var _useContext = useContext(AppContext),
      generalClient = _useContext.generalClient;

  return useCallback(function (arg) {
    if (!generalClient) {
      throw new Error('Client is not initialized');
    }

    if (typeof arg !== 'string') {
      var reqArray = arg.url.map(function (url) {
        return generalClient.get(url, params, ignore404)["catch"](function () {
          return undefined;
        });
      });
      return Promise.all(reqArray).then(function (responses) {
        return responses.filter(function (item) {
          return item !== undefined;
        }).flatMap(function (response) {
          return response.data;
        });
      });
    }

    return generalClient.get(arg, params, ignore404).then(function (res) {
      return res.data;
    });
  }, [generalClient, params, ignore404]);
};

var baseSWRConfig = {
  shouldRetryOnError: false,
  revalidateOnFocus: false
};
export var useQuery = function useQuery(alias, params, swrConfiguration, ignore404) {
  var fetcher = useFetcher(params, ignore404);
  var urlParam = useMemo(function () {
    return Array.isArray(alias) ? {
      url: alias
    } : alias;
  }, [alias]);

  var _useSWR = useSWR(urlParam, fetcher, _objectSpread(_objectSpread({}, baseSWRConfig), swrConfiguration)),
      data = _useSWR.data,
      error = _useSWR.error,
      isValidating = _useSWR.isValidating,
      mutate = _useSWR.mutate;

  return [data, isValidating, error, mutate];
};