import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _ from 'lodash';
import { ALL_COUNTRY_CODES } from '@/buildConfig';
import { SettingKey } from './types';
export function countryCodes(value) {
  return _(ALL_COUNTRY_CODES).map(function (code) {
    return [code, value];
  }).fromPairs().value();
}
export function envKeys(value) {
  return {
    PROD: value,
    UAT: value,
    DEV: value
  };
}
export function moduleKeys(value) {
  return {
    UPSELL: value,
    FORGOTTEN_ITEMS: value,
    QUICK_ORDER: value,
    PROMOS: value,
    SKU_LIMITS: value
  };
}
export function emptySettings() {
  var _ref;

  return _ref = {}, _defineProperty(_ref, SettingKey.imageWeb, undefined), _defineProperty(_ref, SettingKey.imageApp, undefined), _defineProperty(_ref, SettingKey.title, undefined), _defineProperty(_ref, SettingKey.description, undefined), _defineProperty(_ref, SettingKey.cartAddTriggerThreshold, undefined), _defineProperty(_ref, SettingKey.cartViewTriggerThreshold, undefined), _defineProperty(_ref, SettingKey.defaultRecs, undefined), _defineProperty(_ref, SettingKey.strategicSkus, undefined), _defineProperty(_ref, SettingKey.baselineSkuLimitQuantity, undefined), _defineProperty(_ref, SettingKey.extraSkuLimitQuantityMultiplier, undefined), _ref;
}