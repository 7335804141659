import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { GlobalCountryContext } from '@/contexts/GlobalCountryContext';
export var useGlobalCountry = function useGlobalCountry(defaultCountry) {
  var country = useContextSelector(GlobalCountryContext, function (state) {
    return state.country;
  });
  var setCountry = useContextSelector(GlobalCountryContext, function (state) {
    return state.setCountry;
  });
  return useMemo(function () {
    return {
      country: country || defaultCountry,
      setCountry: setCountry
    };
  }, [country, setCountry, defaultCountry]);
};