import { useMemo } from 'react';
export var useURLSearchParams = function useURLSearchParams(_ref) {
  var pathname = _ref.pathname,
      search = _ref.search;
  var urlSearchParams = useMemo(function () {
    return new URLSearchParams(search);
  }, [search, pathname]);
  return {
    urlSearchParams: urlSearchParams,
    search: search,
    pathname: pathname
  };
};