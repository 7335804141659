import { createSlice } from '@reduxjs/toolkit';
import { BUILD_CONFIG } from '@/buildConfig';
import { SOLOMON_DEFAULT_DATE_RANGE } from '@/constants';
import { getDateRange } from '@/views/common/DateRangeSelector/DateRangeOptions';
export var SOLOMON_PARTITION_PARAM = 'partition';
var initialState = {
  filters: [],
  loading: false,
  optionsLoading: false,
  metricsLoading: false,
  metrics: undefined,
  partition: BUILD_CONFIG.inspect.defaultPartition,
  dateRange: getDateRange(SOLOMON_DEFAULT_DATE_RANGE),
  metric: BUILD_CONFIG.inspect.defaultMetric,
  category: 'All Products',
  recallPerformanceIssuesLoading: false
};
var solomonChartSlice = createSlice({
  name: 'solomonChart',
  initialState: initialState,
  reducers: {
    setData: function setData(state, action) {
      var _action$payload = action.payload,
          data = _action$payload.data,
          queryParams = _action$payload.queryParams;

      if (queryParams.drill_down !== state.drillDown) {
        return;
      }

      if (queryParams[SOLOMON_PARTITION_PARAM] !== state.partition) {
        return;
      }

      for (var i = 0; i < state.filters.length; i++) {
        var _state$filters$i = state.filters[i],
            key = _state$filters$i.key,
            value = _state$filters$i.value;

        if (queryParams[key] === undefined || queryParams[key] !== value) {
          return;
        }
      }

      state.data = data;
      state.error = undefined;
    },
    setRecallPerformanceIssues: function setRecallPerformanceIssues(state, action) {
      state.recallPerformanceIssues = action.payload;
    },
    setRecallPerformanceIssuesLoading: function setRecallPerformanceIssuesLoading(state, action) {
      state.loading = action.payload;
    },
    setError: function setError(state, action) {
      state.error = action.payload;
    },
    setOptions: function setOptions(state, action) {
      state.options = action.payload;
    },
    setMenuProps: function setMenuProps(state, action) {
      state.menuProps = action.payload;
    },
    setLoading: function setLoading(state, action) {
      state.loading = action.payload;
    },
    setOptionsLoading: function setOptionsLoading(state, action) {
      state.optionsLoading = action.payload;
    },
    setMetrics: function setMetrics(state, action) {
      state.metrics = action.payload;
    },
    setMetricsLoading: function setMetricsLoading(state, action) {
      state.metricsLoading = action.payload;
    },
    setDateRange: function setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    clearAll: function clearAll(state) {
      state.filters = [];
      state.drillDown = undefined;
      state.error = undefined;
      state.menuProps = undefined;
      state.data = undefined;
      state.loading = false;
      state.optionsLoading = false;
    },
    setURLParams: function setURLParams(state, action) {
      var _action$payload2 = action.payload,
          partition = _action$payload2.partition,
          filters = _action$payload2.filters,
          drillDown = _action$payload2.drillDown,
          metric = _action$payload2.metric,
          dateVal = _action$payload2.dateVal;
      state.partition = partition;
      state.filters = filters;
      state.drillDown = drillDown;
      state.error = undefined;
      state.menuProps = undefined;
      state.metric = metric;
      state.loading = false;
      state.optionsLoading = false;
      state.dateRange = getDateRange(dateVal);
    }
  }
});
var _solomonChartSlice$ac = solomonChartSlice.actions,
    setData = _solomonChartSlice$ac.setData,
    setError = _solomonChartSlice$ac.setError,
    setOptions = _solomonChartSlice$ac.setOptions,
    setMenuProps = _solomonChartSlice$ac.setMenuProps,
    setLoading = _solomonChartSlice$ac.setLoading,
    setOptionsLoading = _solomonChartSlice$ac.setOptionsLoading,
    setDateRange = _solomonChartSlice$ac.setDateRange,
    setURLParams = _solomonChartSlice$ac.setURLParams,
    clearAll = _solomonChartSlice$ac.clearAll,
    setRecallPerformanceIssues = _solomonChartSlice$ac.setRecallPerformanceIssues,
    setRecallPerformanceIssuesLoading = _solomonChartSlice$ac.setRecallPerformanceIssuesLoading,
    setMetrics = _solomonChartSlice$ac.setMetrics,
    setMetricsLoading = _solomonChartSlice$ac.setMetricsLoading;
export { setData, setError, setOptions, setMenuProps, setLoading, setOptionsLoading, setDateRange, setURLParams, clearAll, setRecallPerformanceIssues, setRecallPerformanceIssuesLoading, setMetrics, setMetricsLoading };
export var solomonChartReducer = solomonChartSlice.reducer;