import _ from 'lodash';
import { buildConfigDefault } from './buildConfigDefault';

function getBuildConfig() {
  if (process.env.BUILD_CONFIG === undefined) {
    if (typeof process === 'undefined') {
      console.error('Falling back to default build config, this should not happen in the browser');
    }

    return buildConfigDefault;
  }

  if (_.isString(process.env.BUILD_CONFIG)) return JSON.parse(process.env.BUILD_CONFIG);
  return process.env.BUILD_CONFIG;
}

export var BUILD_CONFIG = getBuildConfig();