import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMemo, useState } from 'react';
import { BUILD_CONFIG } from '@/buildConfig';
import { AuthState } from './authTypes';
import { useCloudSettings } from './useCloudSettings';
export var useGlobalCountryContextValue = function useGlobalCountryContextValue(client, authState) {
  var _useState = useState(undefined),
      _useState2 = _slicedToArray(_useState, 2),
      country = _useState2[0],
      setCountry = _useState2[1];

  var _useCloudSettings = useCloudSettings({
    country: country
  }, function (value) {
    return setCountry(value.country);
  }, {
    urlKey: 'global_country',
    saveDisabled: !BUILD_CONFIG.shouldPersistCountry || authState !== AuthState.AUTHENTICATED,
    fetchDisabled: !BUILD_CONFIG.shouldPersistCountry || authState !== AuthState.AUTHENTICATED
  }, client),
      isCloudSettingsLoaded = _useCloudSettings.isCloudSettingsLoaded;

  return useMemo(function () {
    return {
      isCloudSettingsLoaded: isCloudSettingsLoaded,
      country: country,
      setCountry: setCountry
    };
  }, [country, isCloudSettingsLoaded, setCountry]);
};