import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export function invert(data, fillna) {
  var keys = Object.keys(data);

  if (keys.length === 0) {
    return [];
  }

  var maxKeyLength = Math.max.apply(Math, _toConsumableArray(keys.map(function (key) {
    return data[key].length;
  })));
  var invertedOutput = [];

  var _loop = function _loop(i) {
    var row = {};
    keys.forEach(function (key) {
      row[key] = data[key][i] || fillna;
    });
    invertedOutput.push(row);
  };

  for (var i = 0; i < maxKeyLength; i++) {
    _loop(i);
  }

  return invertedOutput;
}